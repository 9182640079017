import React, { FC } from 'react'
import styled from 'styled-components'
import PageWrapper from '../components/page-wrapper'
import SocialMediaRow from '../components/social-media-row'
import Avatar from '../components/avatar'

const StyCenter = styled.div`
  display: flex;
  justify-content: center;
`

const StyGridColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const StyGridItem = styled.div`
  margin: 2rem;
  max-width: 30rem;
`

const StyH1 = styled.div`
  font-size: 2rem;
  font-weight: 600;
  margin: 0rem 0 2rem 0;
`

const About: FC<{}> = () => {
  return (
    <PageWrapper>
      <StyCenter>
        <StyGridColumn>
          <StyGridItem>
            <Avatar />
          </StyGridItem>
          <StyGridItem>
            <StyH1>Contact</StyH1>
            <div>
              <div>
                Michael Puls
                <br />
                Cosimastraße 206
                <br />
                81927 München
                <br />
                E-Mail: Michael.Puls[at]MiPnet.de
                <br />
                Website:
                {' '}
                <a href="https://mipnet.de" target="new">MiPnet.de</a>
              </div>
              <SocialMediaRow />
            </div>
          </StyGridItem>
          <StyGridItem>
            <StyH1>Usage of</StyH1>
            <ul>
              <li>
                Font:
                {' '}
                <b>Nunito</b>
                {' '}
                by
                {' '}
                <a href="http://sansoxygen.com/" target="new">Vernon Adams</a>
                , Copyright &copy; 2014,
                {' '}
                <a href="http://scripts.sil.org/OFL" target="new">SIL Open Font License Version 1.1</a>
              </li>
              <li>
                No cookies are used.
              </li>
            </ul>
          </StyGridItem>
        </StyGridColumn>
      </StyCenter>
    </PageWrapper>
  )
}

export default About
